import React from "react";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Products from "../components/products";
import Story from "../components/story";
import TeamCards from "../components/teamcards";
import Companies from "../components/companies";

export default function IndexPage() {
  return (
    <Layout pageTitle="Policy Fuel">
      <Hero />
      <Story />
      <TeamCards />
      <Products />
      <Companies />
    </Layout>
  );
}
