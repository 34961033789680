import React from "react";
import PFRow from "./pfrow";

export default function Companies() {
  return (
    <PFRow light={true} id="howwedoit">
      <h4 className="mb-3">How We Do It</h4>
      <p>
        Technology and data at the heart of what we do. Together, they enable us
        to target the right customers that ultimately yield good unit economics,
        and provide an exceptional customer experience every step of the way. We
        work closely with our partners to understand the variables that drive
        their unit economics and the exact make-up of the types of customers
        they want so that ultimately our partners get customers that yield high
        lifetime value relative to cost of acquisition, or achieve a ratio
        between LTV:CAC that drives profitable growth.
      </p>
      <p>
        It starts with using our technology to source leads, or consumers
        actively shopping online, at the right price and who have a high
        propensity to buy a product from one of our carrier partners. Then, we
        engage those consumers via phone and SMS via our proprietary contact
        center solution. This solution is a huge competitive advantage for us as
        it allows us to provide an exceptional consumer experience while
        maximizing throughput through the sales funnel. And do so compliantly in
        accordance with TCPA, CCPA, PCI, etc.
      </p>
      <p>
        As part of our Policy Sales as a Service business model and unique
        approach to supporting both carriers and consumers, we’ve established
        dedicated advisor sales teams to focus exclusively on specific carriers,
        products and markets. This degree of specialization maximizes our value
        proposition for both carriers and consumers. Our technology and
        algorithms match consumers with one of our dedicated teams who has the
        best product for that consumer's needs, which ultimately creates a
        consumer experience that gets a consumer the best product, for the best
        price, in the least amount of time.
      </p>
      <p>
        The end result are happy customers and happy carriers. Rinse and repeat!
      </p>
    </PFRow>
  );
}
