import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.css";
import Navigation from "./navigation";
import Footer from "./footer";

export default function Layout({ pageTitle, children }) {
  return (
    <>
      <Navigation />
      <title>{pageTitle}</title>
      {children}
      <Footer />
    </>
  );
}
