import React from "react";
import { Row, Col } from "react-bootstrap";

export default function Footer() {
  return (
    <Row id="contactus" className="bg-white">
      <Col xs className="top40">
        <div className="text-center m-3">
          <p>
            Policy Fuel, LLC
            <br />
            8501 N. Mopac Expressway Suite 110
            <br />
            Austin, Texas 78757
          </p>
          &copy; 2021 Policy Fuel
        </div>
      </Col>
    </Row>
  );
}
