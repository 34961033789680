import React from "react";
import { CardColumns, Card } from "react-bootstrap";
import PFRow from "./pfrow";

export default function TeamCards() {
  const staff = [
    {
      id: 1,
      name: "Tim Presto",
      title: "Co-founder & CEO, PolicyFuel & Parachute",
      priors: [
        "Marketing Analyst & Product Manager, Progressive",
        "Director of Marketing & Partnerships, Plymouth Rock",
      ],
      expertise: "Strategy, Marketing, FP&A, Analytics, Carrier Relations",
      education: "Duke-Fuqua Business School",
    },
    {
      id: 2,
      name: "Ryan McClintock",
      title: "Co-founder & President, PolicyFuel, Kanopy & Parachute",
      priors: ["GM of Insurance Vertical, Velocify"],
      expertise: "Business development, Agency & sales center technology",
      education: "",
    },
    {
      id: 3,
      name: "Edward Hames",
      title: "Co-founder & COO, PolicyFuel, Kanopy & Parachute",
      priors: ["GM of Insurance & Director of Sales, Consumer United / Goji"],
      expertise:
        "P&C insurance sales and underwriting, Omni-channel monetization",
      education: "",
    },
  ];

  const TeamItems = staff.map((s) => (
    <Card
      key={"card" + s.id}
      className="d-inline-flex p-1 me-3 mb-3"
      style={{ width: "16rem", height: "20rem" }}
    >
      <Card.Body>
        <Card.Title>{s.name}</Card.Title>
        <Card.Subtitle className="my-3">{s.title}</Card.Subtitle>
        <Card.Text>
          <small>
            Prior Roles:{" "}
            {s.priors.map(function (prior, index) {
              return (
                <span key={index}>
                  {prior}
                  <br />
                </span>
              );
            })}
          </small>
        </Card.Text>
      </Card.Body>
      <Card.Footer key={"cardfooter" + s.id}>
        <small className="text-muted">{s.expertise}</small>
      </Card.Footer>
    </Card>
  ));

  return (
    <PFRow light={true} id="whoweare">
      <h4 className="mb-3">Who We Are</h4>
      <p>
        Our team is rooted in deep experience across insurance, agency
        operations, analytics, software, and technology.
      </p>
      <CardColumns className="pb-5 text-center">{TeamItems}</CardColumns>
    </PFRow>
  );
}
