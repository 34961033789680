import React from "react";
import PFRow from "./pfrow";
import { Col, Container, Row } from "react-bootstrap";

export default function Hero() {
  return (
    <PFRow light={true} id="home">
      <div className="py-5">
        <h1 className="display-5 pb-5 fw-bold text-center">
          We Fuel Policy Sales
        </h1>

        <p className="lead text-center">
          We offer proven, scalable solutions for customer acquisition in the
          insurance industry. Our marketing, sales and technology utilities are
          each designed to give our customers a measurable and material
          advantage that ultimately results in high lifetime value customers at
          an optimal customer acquisition cost.
        </p>
      </div>
      <Container>
        <Row>
          <Col className="text-center">
            Policy Fuel, LLC has been acquired by EverQuote, Inc.{" "}
            <a
              href="https://investors.everquote.com/press-releases/default.aspx"
              target="_blank"
              rel="noreferrer"
            >
              Read the press release here.
            </a>
          </Col>
        </Row>
      </Container>
    </PFRow>
  );
}
