import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import pflogo from "../images/pflogo.svg"

export default function Navigation() {
  return (
    <Navbar variant="light" expand="md" className="px-5 bg-white" fixed="top">
      <Navbar.Brand href="#home" className="me-4">
        <img
          alt="Policy Fuel"
          src={pflogo}
          width="200"
          className="d-inline-block align-bottom"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Link href="#ourstory">Our Story</Nav.Link>
          <Nav.Link href="#whoweare">Who We Are</Nav.Link>
          <Nav.Link href="#whatwedo">What We Do</Nav.Link>
          <Nav.Link href="#howwedoit">How We Do It</Nav.Link>
          <Nav.Link href="#contactus">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
