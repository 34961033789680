import React from "react";
import { Row, Col } from "react-bootstrap";

export default function PFRow(props) {
  return (
    <Row className={props.light ? "bg-light" : "bg-white"} id={props.id}>
      <Col className="col-9 mx-auto top40 pb-3">{props.children}</Col>
    </Row>
  );
}
