import React from "react";
import PFRow from "./pfrow";

export default function Story() {
  return (
    <PFRow light={false} id="ourstory">
      <h4 className="mb-3">Our Story</h4>
      <p>
        Our vision was founded by the belief that online shoppers were
        underserved; and understandably so. Insurance is a complicated product
        and the online ecosystem consists of agencies and carriers of all shapes
        and sizes, all of whom provided wildly inconsistent approaches to how
        they try to serve the online consumer. Some consumers wanted to buy
        now, some later, some over the phone, some via text, some wanted to talk
        to many agents, some just one, and on it goes. What was abundantly clear
        is consumers didn’t want to be frustrated by the overall experience, and
        too many were. We aimed to be a company whose mission was to provide the
        kind of experience we’d want for ourselves.
      </p>
      <p>
        But it wasn’t just the consumer who mattered. It was also the carrier,
        because they provided the products we were selling. We had to make them
        happy, too! We quickly learned that profitability was the name of the
        game. Simple, right? Not so much. Carriers are a picky bunch. They want
        very specific types of customers, typically they want a lot of them, and
        ultimately, because they’re a for-profit business, they want the
        business you send them to be profitable. Profitability is all about
        retention, loss ratios, expense ratios, and probably a bunch of other
        ratios. But put simply, they want customers who are going to stick
        around and aren’t going to file a bunch of claims and burn a hole in
        their pocket.
      </p>
      <p>
        And with that, our north star was born: “Drive policy sales
        profitability for our carriers and ourselves while providing exceptional
        consumer experiences”
      </p>
      <p>
        It’s no easy feat. Over time, we realized we needed technology to
        achieve our mission. So, we built it ourselves. Which gave us access to
        deeper and richer data, which empowered us to do more.{" "}
      </p>
      <p>
        Today, we work with some of the largest carriers and agencies in the
        country to drive policy sales for them in extraordinary dedicated and
        exclusive ways. We think we have something special here. Our performance
        validates that belief. And we’re here to help take this industry to new
        heights, one consumer experience, and one carrier relationship at a
        time.{" "}
      </p>
      <p>
        If you’re a carrier or agency looking to grow, we’d love to talk to you!
      </p>
    </PFRow>
  );
}
