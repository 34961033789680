import React from "react";
import PFRow from "./pfrow";

export default function Products() {
  return (
    <PFRow light={false} id="whatwedo">
      <h4 className="mb-3">What We Do</h4>
      Our solutions are built to deliver customers in two principal formats:
      <ul>
        <li>Signed, bound customers, by way of our licensed agents.</li>
        <li>
          Warm Transfers, which offer a direct, telephonic connection to
          in-market consumers;
        </li>
      </ul>
      <p>
        If that all sounds simple, that’s because it’s supposed to. You want to
        grow; we make that possible without the noise, risk and drama you might
        experience doing so internally, or even elsewhere. Our people are
        uncommonly good at this work, and our proprietary technology platform,
        Audacious, is designed to add scale and efficiency to everything they
        do.
      </p>
      <h5 className="mb-3">Products</h5>
      <p>Policy Sales as a Service (PSaaS) in two flavors:</p>
      <ul>
        <li>You provide the leads and we provide the policies.</li>
        <li>We provide the leads and the policies.</li>
      </ul>
      Qualified Warm Transfers in two flavors:
      <ul>
        <li>
          You provide the leads and we warm transfer qualified consumers to you.
        </li>
        <li>We provide the leads we warm transfer qualified consumers.</li>
      </ul>
      <p>
        If you’re an insurance company and you want more customers, or you’re
        not an insurance company but looking to cross-sell your customers
        insurance, you’re talking to the right folks.
      </p>
    </PFRow>
  );
}
